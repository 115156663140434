import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/hamb-atum.css'
import $ from 'jquery' // important: case sensitive.

import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import atumcover from "../images/atum-cover.png" // Tell webpack this JS file uses this image
import atumicones from "../images/atum-icones.png" // Tell webpack this JS file uses this image
import atumicones2 from "../images/atum-icones2.png" // Tell webpack this JS file uses this image
import logonew from "../images/logo-new-atum.png" // Tell webpack this JS file uses this image
import StopMotion from "../images/StopMotion.mp4"

import scrollTo from 'gatsby-plugin-smoothscroll';


function clickHandler(){
  $("#modal1").css("display", "block");

  $("#modal1").css("opacity", "1");
}
function clickHandler2(){
  $("#modal2").css("display", "block");

  $("#modal2").css("opacity", "1");
}
function clickHandler3(){
  $("#modal3").css("display", "block");

  $("#modal3").css("opacity", "1");
}
function closeHandler(){
 
  $("#modal1").css("opacity", "0");
  $("#modal1").css("display", "none");

}

function closeHandler2(){
  $("#modal2").css("opacity", "0");
  $("#modal2").css("display", "none");


}
function closeHandler3(){
  $("#modal3").css("opacity", "0");
  $("#modal3").css("display", "none");


}
const hamburguerBacalhau = () => (
  <Layout>
    <SEO title="Peixe Fácil - Hambúrguer de Atum" />

    <div id="modal1" className="modal">
      
      <div className="modal-container">
        <div className="header-modal">
          <div className="left-col">
          <p className="titulo absara">Fonte de Proteína</p>
          </div>
          <div className="right-col">

          <button className="titulo blue-marine sneak-regular" onClick={closeHandler}>Fechar</button>
          </div>
        </div>
        <div className="body-modal">
           <p className="descricao sneak-regular">
           A proteina é um dos macronutrientes que compõem a dieta alimentar. É principalmente encontrada em alimentos de origem animal apesar de também estar presente noutras fontes à base de plantas como legumes, nozes e sementes. Uma dieta equilibrada deve assegurar uma adequada ingestão de proteina assim como de aminoácidos essenciais.<br></br><br></br> A proteina tem um papel fundamental na:<br></br><br></br>
<b>• Manutenção de massa muscular;</b><br></br>
<b>• Crescimento, reparação e manutenção de células;</b><br></br>
<b>• Reações metabólicas;</b><br></br>
<b>• Resposta imunitária.</b><br></br><br></br>
Uma dieta rica em proteina pode beneficiar a rápida recuperação após o exercício, ajudar na manutenção de peso e sensação de maior saciedade.
           </p>
        </div>
      </div>

    </div>

    <div id="modal2" className="modal">
      
      <div className="modal-container">
        <div className="header-modal">
          <div className="left-col">
          <p className="titulo blue-marine absara">Alto teor em vitamina D e B12</p>
          </div>
          <div className="right-col">

          <button className="titulo sneak-regular" onClick={closeHandler2}>Fechar</button>
          </div>
        </div>
        <div className="body-modal">
           <p className="descricao sneak-regular">
           A síntese de vitamina D é essencialmente realizada pela pele, através da exposição solar. Nos meses de menor exposição solar deverá ser colmatada com uma maior ingestão de alimentos ricos em vitamina D. As fontes naturais de vitamina D na alimentação encontram-se principalmente presentes em peixes como atum, dourada, salmão, robalo e pescada.<br></br><br></br>A vitamina D tem um papel crítico na:<br></br><br></br>
           <b>• Função imunitária;</b><br></br>
           <b>• Na prevenção de infeções respiratórias;</b><br></br>
           <b>• Está associada ao fortalecimento muscular, uma vez que participa no processo de formação dos músculos.</b><br></br>
           <br></br><br></br>
           Também conhecida como Cobalamina, a Vitamina B12 é uma vitamina essencial ao organismo que não sendo produzida pelo nosso corpo há necessidade de ser ingerida através da alimentação. A única fonte natural de Vitamina B12 são alimentos de origem animal e esta vitamina apresenta funções importantes no nosso organismo como:
           <br></br><br></br><b>• Auxiliar na normal função de células nervosas;</b><br></br>
<b>• Síntese de material genético;</b><br></br>
<b>• Formação de células sanguíneas;</b><br></br>
<b>• Papel importante na manutenção de ossos saudáveis.</b><br></br>
           </p>
        </div>
      </div>

    </div>


    <div id="modal3" className="modal">
      
      <div className="modal-container">
        <div className="header-modal">
          <div className="left-col">
          <p className="titulo blue-marine absara">Fonte de potássio e fósforo</p>
          </div>
          <div className="right-col">

          <button className="titulo sneak-regular " onClick={closeHandler3}>Fechar</button>
          </div>
        </div>
        <div className="body-modal">
           <p className="descricao sneak-regular">
             <b>Fonte de potássio</b><br></br><br></br>
             Potássio é um dos minerais mais importantes do corpo. Um consumo adequado de potássio é
elementar à saúde cardiovascular e saúde dos nossos ossos levando à redução do risco de
doença arterial coronária e acidente vascular. Este mineral ajuda também na:<br></br><br></br>
<b>• Regulação do balanço de fluidos, o que evita riscos de desidratação e
consequentemente problemas a nível de rins e coração;</b><br></br>
<b>• Manutenção de um bom funcionamento do sistema nervoso, associado à ativação de
impulsos nervosos.</b><br></br>

           <br></br><br></br>
          <b>Fonte de fósforo</b>
          <br></br><br></br>
          Alimentos ricos em proteina como carne e peixe são uma boa fonte de Fósforo. Este mineral é
utilizado no nosso organismo principalmente para:
<br></br><br></br>
<b>• Produção de energia, tendo também uma função no seu uso e manutenção de reservas;</b><br></br>
<b>• Manutenção de músculos fortes;</b><br></br>
<b>• Construção de uma forte dentição;</b><br></br>
<b>• Filtração de desperdício nos rins;</b><br></br>
<b>• Manutenção de batimento cardíaco regular;</b><br></br>
<b>• Balanço e uso de outras vitaminas.</b><br></br>
           </p>
        </div>
      </div>

    </div>


    <div className="wrapper-bg-atum">
        <div className="center-col-bg">
        <div className="logo-brand">
        <img src={logonew} alt="" />

        </div>
        </div>
     
      </div>

    <div id="section-1-atum">
      <div className="wrapper-container">
   
        
        {/* <div className="mobile-logo">
            <img src={logonew} alt="Logo" />
          </div> */}
          
        <div className="titulo absara blue-marine">
          <p> Hambúrguer de Atum</p>
        </div>
          <div className="descricao sneak-regular opacity">
            <p> Desfrute do nosso suculento, Hambúrguer de Atum! A forma mais fácil, prática e rápida de comer peixe. </p>
          </div>
      </div>
      <div className="container-video">
          <div className="left-col-video">
          <video className="video-class" preload='auto' controls autoPlay loop playsInline muted>
            <source src={StopMotion} type="video/mp4" />
          </video>

          </div>
          <div className="right-col-video">
            <div className="container-right-col">
            <p className="absara titulo blue-marine">Confeção</p>
            <p className="sneak-regular opacity ">Siga as instruções deste pequeno video para conseguir tirar o melhor partido destes maravilhosos hambúrgueres.</p>
            </div>
          </div>
      </div>
      <div id="caracts-section-atum">
      <div className="caracts-wrapper">
        <div className="left-col">
          <div className="text-element">
            <div className="titulo absara blue-marine">
              Nutrição
            </div>
            <div className="value sneak-regular ">
            <button onClick={clickHandler}><span className="span-class">Fonte de proteína</span></button><br></br>
            <button onClick={clickHandler2}><span className="span-class">Alto teor em vitamina D, B6 e B12</span></button><br></br>
            <button onClick={clickHandler3}><span className="span-class">Fonte de potássio e fósforo</span></button>

              
            </div>
          </div>

        </div>
        <div className="right-col">
        <div className="text-element">
            <div className="titulo absara blue-marine">
              Método de confeção
            </div>
            <div className="value">
              <div className="icon">
              <img src={atumicones} alt="Logo" />
              </div>
              <div className="value sneak-regular opacity white">
                <p>15 minutos no forno a temperatura de 180º Celsius</p>

              </div>
            

              
            </div>
            <div className="value">
              <div className="icon">
              <img src={atumicones2} alt="Logo" />
              </div>
              <div className="value sneak-regular opacity white">
                <p>5 minutos na frigideira</p>

              </div>
            

              
            </div>
          </div>
          
          </div>
      </div>
      </div>
    </div>
    <div className="wrapper-3-atum">
    <div className="btn-div">
    <Link to="/" style={{ textDecoration: 'none' }}> <button className="btn sneak-bold">Descubra outros produtos!</button></Link>

          </div>
    </div>

  </Layout>
)


export default hamburguerBacalhau
